<template>
    <div v-if="sections.length > 0" v-for="section in sections">
        <p v-if="section.contentType === 'text'">{{ section.text + " " }}</p>
        <iframe v-else-if="section.contentType === 'iframe'" v-bind:src="section.url.toString()"
            allow="fullscreen; picture-in-picture; web-share; autoplay"></iframe>
        <a v-else-if="section.contentType === 'link'" v-bind:href="section.url.toString()">{{ section.url + " " }}</a>
    </div>
    <p v-else>(Nessuna descrizione)</p>
</template>

<script setup lang="ts">
type ContentSection = {
    readonly contentType: "text",
    readonly text: string,
} | {
    readonly contentType: "iframe",
    readonly url: URL,
} | {
    readonly contentType: "link",
    readonly url: URL,
};

const { text } = defineProps<{ text?: string }>()

const getYoutubeVideoId = (url: URL): string => {
    if (url?.href.includes("watch")) {
        return url.searchParams.get("v")!
    }
    return url?.href.split("/").at(-1)!
}

const getSectionFromUrl = (url: URL): ContentSection => {
    if (url.host.startsWith("youtu.be") || url.host.startsWith("www.youtube.com")) {
        const videoUrl = new URL('https://www.youtube.com/embed/' + getYoutubeVideoId(url));
        if (!!videoUrl) {
            return {
                contentType: "iframe",
                url: videoUrl,
            }
        }
    }
    if (url.host.startsWith("dai.ly")) {
        const videoUrl = new URL('https://geo.dailymotion.com/player.html?mute=false&video=' + url.pathname.split("/").at(-1)!);
        if (!!videoUrl) {
            return {
                contentType: "iframe",
                url: videoUrl,
            }
        }
    }
    return {
        contentType: "link",
        url: url,
    }
}

const getSection = (word: string): ContentSection => {
    try {
        const url = new URL(word);
        if (!!url) {
            return getSectionFromUrl(url);
        }
    } catch (err) {
        console.log("Could not create URL section: " + err)
    }
    return {
        contentType: "text",
        text: word,
    }
}

const getSections = (text: string): ContentSection[] => {
    const allSections = text.split(' ').map(getSection);
    const finalSections: ContentSection[] = []
    for (const section of allSections) {
        // If this is not a text section, add it as-is
        if (section.contentType !== "text") {
            finalSections.push(section)
            continue
        }
        const previousSection = finalSections.at(-1)
        // If the previous section is not a text section, add the new one as-is
        if (!!!previousSection || previousSection.contentType !== "text") {
            finalSections.push(section)
            continue
        }
        // Otherwise, remove the previous section, combine it with the new one and add a new text section with the content combined
        finalSections.pop()
        const aggregatedText = previousSection.text + " " + section.text
        finalSections.push({
            contentType: "text",
            text: aggregatedText,
        })
    }
    return finalSections
}

const sections = readonly(ref(!!text ? getSections(text) : []))
</script>

<style lang="css" scoped>
iframe,
video {
    border: 0;
    width: 100%;
    aspect-ratio: 16/9;
}

button {
    border: 0;
    width: 100%;
    aspect-ratio: 16/9;
    background-color: var(--grey-100);
    color: var(--grey-900);
    border-radius: var(--m-small);
    font-size: var(--font-size-medium-large);
}

button:hover {
    background-color: var(--grey-200);
    cursor: pointer;
}

p,
a {
    display: inline;
    overflow: hidden;
    text-wrap: wrap;
    word-break: break-all;
}
</style>